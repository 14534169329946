<template>
  <fw-layout wide>
    <fw-panel :title="$t('justifications')" featured :loading="loading"> </fw-panel>

    <fw-panel :title="$t('currentYear')" class="mt-5">
      <ContentLoader v-if="loading" />
      <fw-panel-info
        v-else-if="!currentYearJustifications || !currentYearJustifications.length"
        centered
        empty
        class="p-3"
      >
        {{ $t('noJustifications') }}
      </fw-panel-info>
      <div v-else>
        <RecordManageJustification
          v-for="data in currentYearJustifications"
          :key="data.justification.key"
          :item="data.justification"
          :users="users"
        ></RecordManageJustification>
      </div>
    </fw-panel>

    <fw-panel-info label="Justifications (raw)">
      <json-viewer :value="{ justifications, currentYearJustifications }"></json-viewer>
    </fw-panel-info>
  </fw-layout>
</template>

<script>
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import RecordManageJustification from '@/components/records/RecordManageJustification'

import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    ContentLoader,
    RecordManageJustification,
  },

  data() {
    return {
      loading: false,
      currentYearJustifications: [],
      justifications: [],
      users: null,
      year: Dates.now().year(),
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },
  },

  mounted() {
    this.getJustifications()
  },

  methods: {
    async getJustifications() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getJustifications({ year: this.year, with_users: true })
        this.currentYearJustifications = response.data
        this.users = response.users
        console.log('getJustifications :>> ', response)
      })

      this.loading = false
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "justifications": "Justificações",
    "currentYear": "Ano atual",
    "noJustifications": "Sem justificações"
  },
  "en": {
    "justifications": "Justifications",
    "currentYear": "Current year",
    "noJustifications": "No justifications"
  }
}
</i18n>
